.header-container {
    background: #000;
    color: #f1f1f1;
    display: flex;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 65px;
    z-index: 999px;
}

.animated-border {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: linear-gradient(-45deg, #0625D8, #1AB83F, #FAFD1C);
    background-size: 300% 300%;
    height: 5px;
    animation: gradient 5s ease alternate infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.header-item-container {
    margin: 0px 4px;
    cursor: pointer;
    padding: 4px;
}

.menu-icon {
    cursor: pointer;
}