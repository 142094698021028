.presentation {
  background-image: url("../../assets/presentation.jpeg");
  background-size: cover;
  background-position: center;
  height: auto;
  position: relative;
}

.text-container {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;

  padding: 20px;
  transition: all 0.5s ease;
}


.btn-grad {
  background-image: linear-gradient(to right, #ffb347 0%, #ffcc33  51%, #ffb347  100%);
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  font-weight: bold;
}

.btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}
       