.sidebar-menu-item {
  font-weight: bold;
  background-color: #fff;
  width: 100%;
  padding: 6px 12px;
  border-radius: 0.375rem;
  display: flex;
  color: #404142;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.sidebar-menu-item:hover {
  background-color: #ccc;
}

.sidebar-menu-active {
  background-color: #1AB83F;
  color: white;
}

.sidebar-menu-active:hover {
  background-color: #0b8628;
}

.svg-icon {
  width: 25px;
  margin-right: 8px;
}

.svg-icon-white {
  filter: brightness(0) invert(1);
}

.tyre-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 8px;
  padding: 10px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.tyre-card:hover {
  box-shadow: 0 0 10px #ccc;
}

.tyre-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #404142;
  letter-spacing: 0px;
}

.tyre-price {
  font-weight: 700;
  font-size: 28px;
  line-height: 20px;
  color: #404142;
}

.tyre-price-complement {
  color: #8c8d8f;
  font-size: 14;
  line-height: 24px;
  font-weight: 400;
  
}

.tyre-buy-btn {
  background: #1AB83F;
  color: white;
  font-weight: bold;
  width: 100%;
  padding: 6px 12px;
  border-radius: 0.375rem;
  transition: 0.5s;
}

.tyre-buy-btn:hover {
  background: #0b8628;
}

.tyre-buy-btn-icon {
  width: 22px;
  margin-right: 8px;
}

.tyre-btn-background-selected {
  background: #1AB83F;
  color: #fff;
}

.tyre-btn-background {
  background: #fff;
  color: #404142;
}

@media screen and (max-width: 768px) {

  .tyre-title {
    font-size: 14px;
  }

  .tyre-price {
    font-size: 24px;
  }

  .tyre-price-complement {
    font-size: 12px;
  }
}